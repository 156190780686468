// 動畫還沒做好!!!
@import "./styles//reset.scss";
@import url("./styles/animate.css");

.TTPushNFT-container {
  width: 100%;
  min-height: 100vh;
  background: #59b4e6;
  background: -moz-linear-gradient(top, #59b4e6 0%, #ee89a7 51%, #ffffff 100%);
  background: -webkit-linear-gradient(
    top,
    #59b4e6 0%,
    #ee89a7 51%,
    #ffffff 100%
  );
  background: linear-gradient(to bottom, #59b4e6 0%, #ee89a7 51%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#59b4e6', endColorstr='#ffffff', GradientType=0);
  background-attachment: fixed;
  .note {
    color: #fff;
    text-align: center;
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
  .main-title {
    position: sticky;
    z-index: 5;
    top: 0px;
    font-size: 30px;
    padding: 30px 0;
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-shadow: 2px 5px 5px #72a0c2a3;
    background: -moz-linear-gradient(
      top,
      rgba(89, 180, 230, 1) 0%,
      rgba(89, 180, 230, 1) 60%,
      rgba(89, 180, 230, 0) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(89, 180, 230, 1) 0%,
      rgba(89, 180, 230, 1) 60%,
      rgba(89, 180, 230, 0) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(89, 180, 230, 1) 0%,
      rgba(89, 180, 230, 1) 60%,
      rgba(89, 180, 230, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#59b4e6', endColorstr='#0059b4e6', GradientType=0);
  }
  .middle-container {
    max-width: 800px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  p.buy {
    font-size: 17px;
    font-weight: bold;
    a {
      display: block;
      padding: 8px 10px 8px 33px;
      position: relative;
      border-radius: 50px;
      background-color: #fff;
      color: #ff6699;
      &::after {
        position: absolute;
        width: 20px;
        height: 20px;
        display: block;
        left: 5px;
        top: calc(50% - 10px);
        background: url("./public/icons/icon-buy.svg") center center no-repeat;
        background-size: contain;
        content: "";
      }
      &:hover {
        background-color: #ff6699;
        color: #fff;
      }
    }
  }
  .top-content {
    width: 100%;

    .an-repeat {
      animation-iteration-count: infinite;
    }
  }
  .banner-content {
    width: 100%;
    // margin-top: -38px;
  }
  .item-content {
    width: 100%;
    padding: 10px 0 130px 0;
    swiper-container {
      width: 100%;
    }

    swiper-slide {
      background-position: center;
      background-size: cover;
      width: 270px;
      height: 380px;
      background-color: #fff1f196;
      border-radius: 20px;
      padding: 20px;
      position: relative;
      .top-photo {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 15px;
          height: 15px;
          display: block;
          background-color: #fff;
          border-radius: 10px;
          top: -8px;
          left: -7px;
        }
        &::after {
          position: absolute;
          content: "";
          width: 17px;
          height: 16px;
          display: block;
          background: url("./public/images/small-vector.png") center center
            no-repeat;
          background-size: contain;
          top: -14px;
          right: 2px;
        }
        img {
          object-fit: cover;
        }
      }
    }
    .img-new {
      position: absolute;
      width: 88px;
      right: -13px;
      top: 6px;
      z-index: 5;
    }
    .top-photo {
      padding: 8px 5px 5px 5px;
      img {
        display: block;
        width: 100%;
        border-radius: 15px;
      }
    }
    .info {
      padding: 10px 5px 0 5px;
      position: relative;
      h3 {
        font-size: 18px;
        padding-bottom: 5px;
        font-weight: bold;
      }
      p {
        padding: 2px 0;
        font-size: 15px;
        &.buy {
          position: absolute;
          right: 0px;
          bottom: 0;
        }
      }
    }
    .pro-content {
      max-width: 500px;
      margin: 0 auto;
      padding: 15px 25px;
      background: url("./public/images/bg-vector.png") center top no-repeat;
      background-size: contain;
      .gold {
        text-align: center;
        font-weight: bold;
        font-size: 36px;
        color: #fff;
        padding-bottom: 10px;
        text-shadow: 2px 5px 5px #72a0c2a3;
      }
      .info {
        h3 {
          font-size: 26px;
          color: #fff;
        }
        h4 {
          font-weight: bold;
          font-size: 20px;
          color: #fff;
          padding: 8px 0;
        }
        p {
          font-size: 18px;
          color: #fff;
          line-height: 1.4;
        }
        a {
          color: #ea5a85;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .done {
        text-align: center;
        h3 {
          font-size: 32px;
        }
        h4 {
          font-size: 24px;
        }
        .img-done {
          max-width: 80px;
          display: inline-block;
          padding: 20px 0;
        }
      }
      p.buy {
        position: initial;
        font-size: 24px;
        margin: 20px auto;
        text-align: center;
        a {
          display: inline-block;
          padding: 12px 20px 12px 55px;
          &::after {
            width: 30px;
            height: 30px;
            left: 12px;
            top: calc(50% - 15px);
          }
        }
      }
    }
  }
  .collect-list {
    padding: 5px 20px 80px 20px;
    .pro-content {
      background: none;
      background-color: #e6e6e696;
      border-radius: 20px;
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 15px;
      .top-photo {
        flex: 50%;
        .image-container {
          position: relative;
          width: 100%;
          padding-bottom: 100%; /* 創建正方形區域 */
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }
        }
      }
      .info {
        padding-left: 8px;
        flex: 50%;
        p {
          font-size: 15px;
          line-height: 1.1;
          text-align: justify;
        }
      }
    }
  }
}

.footer-btns {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px 0px;
  z-index: 5;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+97&0+0,1+73 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 73%,
    rgba(255, 255, 255, 1) 97%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 73%,
    rgba(255, 255, 255, 1) 97%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 73%,
    rgba(255, 255, 255, 1) 97%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

  ul.menu {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    background: url("./public/images/line.svg") center top no-repeat;
    background-size: contain;
    li {
      display: inline-block;
      padding: 6px 9px 0 9px;
      position: relative;
      .li-a {
        min-width: 96px;
        display: block;
        position: relative;
        .icon {
          width: 62px;
          height: 62px;
          display: block;
          margin: 0 auto;
          background-color: #ffffffea;
          border: 1px solid #fff;
          border-radius: 50px;
        }
        p {
          font-size: 16px;
          font-weight: bold;
          color: #ee89a7;
          padding: 10px 0;
        }
        &:hover {
          .icon {
            background-color: #fff;
          }
        }
      }
    }
    @media all and (max-width: 399px) {
      li {
        padding: 6px 6px 0 6px;
        a {
          .icon {
            width: 58px;
            height: 58px;
          }
        }
      }
    }
  }
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 顯示的最大行數 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

@media only screen and (min-width: 500px) {
  .truncate {
    -webkit-line-clamp: 5;
  }
}

@media all and (min-width: 800px) {
  .truncate {
    -webkit-line-clamp: 8;
  }
}

.expand {
  -webkit-line-clamp: unset;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
